import { useProductsStore } from '~/store/productsStore.js'

// if we have preselected diamond disable shape and stone type filters

export default defineNuxtRouteMiddleware(async (to, from) => {
  const productStore = useProductsStore()

  if (!process.client) return
  if (from != null) window?.DataLayer?.pageView()

  if (to.query.step !== from.query.step) {
    resetGglTrackCalls()
  }

  // Close mobile menu when refreshing the page
  // not sure that it's needed
  // if (window.innerWidth <= 769 && document.querySelector('#inner-nav').offsetWidth > 0) {
  //   document.body.classList.remove('menu')
  //   document.querySelector('#inner-nav').style.width = '0'
  //   document.querySelector('#inner-nav .contentArea').style.display = 'none'
  // }

  // TODO: drop/updated after  https://77diamonds.atlassian.net/browse/WV-836 is done. it's related
  if (window?.notFirstCall) {
    window._h1 = null
    window._h2 = null
    window._h3 = null
  }

  if (to.query.category !== from?.query?.category || to.query.category) {
    await productStore.getCategory({ category: to.query.isExpressShop ? 12 : to.query.category })
  }
})
